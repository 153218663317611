import * as React from 'react';
import '../css/homepage.scss';
import { graphql } from 'gatsby';
import Markdown from '../components/Markdown';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import heroImg from '../images/hero.png';
import heroMobileImg from '../images/hero-mobile@2x.png';

export const query = graphql`
  {
    allStrapiHomepage {
      nodes {
        footer
        hero
        title1
        title2
        title3
        content1 {
          data {
            content1
          }
        }
        content2 {
          data {
            content2
          }
        }
        content3 {
          data {
            content3
          }
        }
      }
    }
  }
`;

export const LOCALIZED_PAGES = { pl: '/', en: '/' };

const IndexPage = ({ data }) => {
  return (
    <Layout localizedPages={LOCALIZED_PAGES}>
      <Seo title="Reformy dla lepszej przyszłości" />
      <div className="homepage__hero">
        <h1>
          <span className="homepage__hero-highlight">Instytut Reform —</span>
          <br />
          {data.allStrapiHomepage.nodes[0].hero}
        </h1>
        <img src={heroImg} alt="" className="homepage__hero-img" />
        <img src={heroMobileImg} alt="" className="homepage__hero-mobile-img" />
      </div>
      <div className="homepage">
        <div className="homepage__block">
          <h2>{data.allStrapiHomepage.nodes[0].title1}</h2>
          <Markdown>
            {data.allStrapiHomepage.nodes[0].content1.data.content1}
          </Markdown>
        </div>
        <div className="homepage__block">
          <h2>{data.allStrapiHomepage.nodes[0].title2}</h2>
          <Markdown>
            {data.allStrapiHomepage.nodes[0].content2.data.content2}
          </Markdown>
        </div>
        <div className="homepage__block">
          <h2>{data.allStrapiHomepage.nodes[0].title3}</h2>
          <Markdown>
            {data.allStrapiHomepage.nodes[0].content3.data.content3}
          </Markdown>
        </div>
        <div className="homepage__footer">
          <h2 className="no-square">
            <Markdown disallowedElements={['p']} unwrapDisallowed={true}>
              {data.allStrapiHomepage.nodes[0].footer}
            </Markdown>
          </h2>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
